:root {
  --scale: 1.01;
  --x: 0;
  --y: 0;
}

body {
  margin: 0;
  background-color: black;
  outline: none;
  border: none;
  overflow: hidden;
}

#wrapper {
  width: 100vw;
  height: 32vh;
  overflow: hidden; /* Ensure no overflow issues */
}

#image {
  width: 100%;
  height: 100%;
  background-image: url("../assets/photo-nasdaq-2.jpg");
  background-size: cover;
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  transform: translateX(var(--x)) translateY(var(--y)) scale(var(--scale));
  transition: ease-out 0.7s;
}
