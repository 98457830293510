.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emailForm {
  display: flex;
  flex-direction: column;
}

input,
textarea {
  margin: 10px 0px;
}

html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
}